var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-steps",
        {
          staticStyle: { margin: "20px" },
          attrs: { active: _vm.activeStep, "finish-status": "success" }
        },
        [
          _c("el-step", {
            attrs: {
              title: _vm.$t("scene.step", [1]),
              description: _vm.$t("scene.step-four-desc")
            }
          }),
          _c("el-step", {
            attrs: {
              title: _vm.$t("scene.step", [2]),
              description: _vm.$t("scene.step-two-desc")
            }
          }),
          _c("el-step", {
            attrs: {
              title: _vm.$t("scene.step", [3]),
              description: _vm.$t("scene.step-three-desc")
            }
          })
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.steps[_vm.activeStep], {
            tag: "component",
            on: { prevStep: _vm.prevStep, nextStep: _vm.nextStep }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }